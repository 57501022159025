<!--
 * @Author: 张阳帅
 * @Date: 2024-09-25 09:52:37
 * @LastEditTime: 2024-11-21 16:30:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\patient\cardList.vue
-->
<template>
  <div class="patientlist_page">
    <div class="page_main">
      <div class="main_item">
        <div class="item_info" @click="getSetDefaultPatientData(patientList)">
          <img class="info_img" src="https://lanzhoutech.oss-cn-shanghai.aliyuncs.com/naokeWebImages/h5/public_user_info.png" alt="">
          <div class="info_name">
            <div class="name_xm">
              <a v-if="patientList.sfmr=='1'" class="xm_sfmr">默认</a>
              {{ patientList.xm }} 
            </div>
            <div class="name_zjh">
              手机号：{{ encryptPhoneNum(patientList.sjh) }}
            </div>
            <div class="name_zjh">
              证件号：{{ encryptIdCordNum(patientList.zjh) }}
            </div>
          </div>
          <!-- <div class="item_delete">
              <van-icon class="delete_edit" name="edit" size="23" color="#1e8dff" @click.stop="editPatientAction(patientList)" />
              <van-icon name="delete" size="23" color="#1e8dff" @click.stop="deletePatientAction(patientList)" />
            </div> -->
        </div>
        <!-- <div v-for="item in patientList.patientCard" class="item_card" @click="getpatientCardDetail(patientList)">
            <div class="card-left">
              <div class="card_xm" >
                {{ item.jzklxName }}
              </div>
              <div class="card_xm" style="margin-top: 2px;">
                {{ item.jzkh }}
              </div>
              <a class="xm_sfmr">默认</a>
            </div>
            
          </div> -->
      </div>
      <div class="card_yymc">
        <div>{{ patientList.yymc }}（{{ patientList.patientCard && patientList.patientCard.length }}张卡）</div> 
        <div class="refrsh">
          <van-icon name="replay" color="#1E8DFF" />
          <span style="margin-left:6px; color: #1E8DFF;cursor: pointer;" @click="refrshCard(patientList.id)">刷新</span>
        </div>
      </div>
      <div v-if="patientList.patientCard && patientList.patientCard.length >0" class="item_card" @click="getpatientCardDetail(patientList)">
        <div v-for="(item,index) in patientList.patientCard" :key="index" class="card-cell">
          <div>
            {{ item.jzklxName }}
          </div>
          <div class="card-jzkh">
            {{ item.jzkh }}
          </div>
          <a v-if="item.sfmr=='1'" class="xm_sfmr">默认</a>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
  import { gjcModel } from '../../../api/gjcApi'
  import { encryptPhoneNum, encryptIdCordNum} from '@/utils'
  
  export default {
      data(){
          return{
              page:6, //页码
              patientList:{patientCard:[]} //就诊人列表
          }
      },
      mounted() {
    
          this.getPatientListData(this.$route.query.patientId)
      },
      methods:{
          getPatientListData(id){
              gjcModel.getPatientDetailData(id).then(res=>{
                  console.log('获取就诊人列表', res)
                  this.patientList = res.data
  
              })
          },
          refrshCard(id){
            gjcModel.refreshCard(id).then(res=>{
                if(res.code == 200){
                    console.log('获取就诊人列表', res)
                    this.$toast('刷新成功')
                    this.getPatientListData(id)
                }
                 
              })
          },
          //手机号码脱敏
          encryptPhoneNum(num) {
              return num ? encryptPhoneNum(num) : ''
          },
          //身份证号码脱敏
          encryptIdCordNum(num) {
              return num ? encryptIdCordNum(num) : ''
          },
          //删除就诊人
          deletePatientAction(item){
              // console.log('删除就诊人', item)
              this.$dialog.alert({
                  title: '删除提示',
                  message: '确认删除就诊人[ '+item.xm+' ]吗?',
                  showCancelButton: true
              }).then(() => {
                  this.getDeletePatientData(item.id)
              }).catch(() => {
                  this.$dialog.close()
              })
          },
          //编辑就诊人
          editPatientAction(item){
              console.log('编辑就诊人', item)
              this.$router.push({
                  path: '/patientEdit',
                  query: {
                      id: item.id
                  }
              })
          },
          //删除就诊人
          getDeletePatientData(id) {
              gjcModel.getDeletePatientData(id).then(res=>{
                  console.log('删除就诊人', res)
                  this.$toast('删除成功')
                  this.getPatientListData()
              })
          },
          //设置默认就诊人
          getSetDefaultPatientData(item) {
              gjcModel.getSetDefaultPatientData(item.id).then(res=>{
                  console.log('设置默认就诊人', res)
                  this.$router.back()
              })
          },
          //添加就诊人页面
          getPatientAddAction(){
              this.$router.push({
                  path: '/patientAdd'
              })
          },
          getpatientCardDetail(){
          }
      }
  }
  </script>
  <style scoped lang='less'>
  .patientlist_page{
      background-color: #f5f5f5;
      height: 100vh;
  }
  .card_yymc{
            font-size: 30px;
            font-weight: bold;
            padding:24px 32px 0;
            display: flex;
            justify-content: space-between;
            span{
                 color: #6A6D78;
                 font-size: 28px;
            }
          }
  .page_main{
      background-color: white;
      height: 100vh;
      overflow: auto;
      .main_item{
          margin: 24px 24px;
          // padding: 20px 24px;
          background: rgba(30,141,255,0.1);
          border-radius: 24px;
          padding: 10px 0 20px 0;
        -webkit-box-shadow:0px 5px 15px 0px #e9f1fa ;
        -moz-box-shadow:0px 5px 15px 0px #e9f1fa ;
        box-shadow:0px 5px 15px 0px #e9f1fa ;
          .item_delete{
              position: relative;
              top: 25px;
              left: 20px;
              width: 120px;
              height: 50px;
              .delete_edit{
                  margin-right: 20px;
              }
          }
          .item_info{
              display: flex;
              justify-content: flex-start;
              padding: 45px 20px;
              // background: rgba(30,141,255,0.1);
              .info_img{
                  width: 100px;
                  height: 100px;
              }
              .info_name{
                 margin-left: 24px;
                .name_xm{
                    display: flex;
                    align-items: center;
                    font-size: 32px;
                    color: #222B31;
                    line-height: 42px;
                    font-weight: bold;
                    .xm_sfmr{
                        margin-right: 16px;
                        padding: 2px 8px;
                        font-size: 26px;
                        color: #3AC39E;
                        line-height: 30px;
                        background: #E0F5EF;
                        border-radius: 4px;
                        border: 1px solid #3AC39E;
                    }
                    .xm_sjh{
                        margin-left: 16px;
                    }
                }
                .name_zjh{
                    margin-top: 24px;
                    font-size: 28px;
                    color: #6A6D78;
                    letter-spacing: 1px;
                }
              }
          }
      }
    .item_card{
        display: flex;
        flex-direction: column;
        margin: 24px 32px;
        padding: 24px 32px;
        border-radius: 16px;
        border: 1px solid #B2B2B2;
        background-color:#fff;
        -webkit-box-shadow:0px 3px 15px 0px #e9f1fa ;
        -moz-box-shadow:0px 3px 15px 0px #e9f1fa ;
        box-shadow:0px 3px 15px 0px #e9f1fa ;
        .xm_sfmr{
            margin-left: 12px;
            border-radius: 4px;
            padding: 2px 8px;
            font-size: 22px;
            background: rgba(30,141,255,0.1);
            color: #1E8DFF;
            font-weight: normal;
        }
        .card-cell{
            display: flex;
            align-items: center;
            background: #E9F4FF;
            border-radius: 8px;
            padding: 24px;
            font-size: 30px;
            color: #3C4455;
            line-height: 42px;
            .card-jzkh{
                margin-left: 12px;
            }
        }
        .card-cell:not(:last-child){
            margin-bottom: 24px;
        }      
    }
  }
  .page_bottom{
      // position: absolute;
      // top: 0vh;
      // height: 12vh;
      width: 100%;
      background-color: white;
      .bottom_add{
          // display: flex;
          // justify-content: center;
          // justify-items: center;
          margin: 10px 50px;
          padding: 20px 50px;
          // background-color: #1e8dff;
          border-radius: 50px;
          font-size: 32px;
          color: #1e8dff;
          text-align: center;
          border:1px solid #1e8dff;
      }
  }
  
  </style>
  